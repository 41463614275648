// The Header component which renders the header image for the Tic Tac Toe application
function Header() {
    return (
      // A div container holding the header image
        // The header image with a CSS class for styling and an alt text for accessibility
      <div>
        <img className="header" src="header.png" alt="TICTACTOE"/>
      </div>
    );
  }
  
  // Exporting the Header component as the default export
  export default Header;
  