// Importing React hooks and components needed for the Game component
import React, { useState, useEffect } from 'react';
import Board from './Board';
import minimax from './minimax';

// The main Game component handling the logic of the tic-tac-toe game
function Game(props) {
  // Initializing state variables to keep track of the game's state
  const [history, setHistory] = useState([Array(9).fill(null)]); // The history of moves, represented as an array of board states
  const [stepNumber, setStepNumber] = useState(0); // The current step number in the game's history
  const [isXNext, setIsXNext] = useState(true); // Boolean indicating if it is X's turn to move next
  const [isSteppingBack, setIsSteppingBack] = useState(false); // Boolean indicating if the game is currently stepping back in history
  const [winningLine, setWinningLine] = useState(null); // The winning line, represented as an array of indices, or null if there is no winner

  // useEffect hook to handle the game logic for the AI player using the minimax algorithm
  useEffect(() => {
    // Checking if it is AI's turn to play (O's turn) and the game is not in the process of stepping back
    if (!isXNext && !isSteppingBack) {
      // Creating a copy of the game history up to the current step and getting the current board state
      const historyCopy = history.slice(0, stepNumber + 1);
      const current = historyCopy[stepNumber];
      const squaresCopy = current.slice();
      
      // Finding the best move for the AI using the minimax algorithm
      const aiIndex = findBestMove(squaresCopy, 'O', 'X');

      // If a valid move was found and there is no winner yet, update the board state with the AI's move
      if (aiIndex !== null && !checkWinner(squaresCopy)) {
        squaresCopy[aiIndex] = 'O';
        setHistory(historyCopy.concat([squaresCopy]));
        setStepNumber(historyCopy.length);
        setIsXNext(true); // Setting the turn back to the human player (X)
      }

      // Checking if there is a winner and updating the winning line state
      const winnerInfo = checkWinner(squaresCopy);
      if (winnerInfo) {
        setWinningLine(winnerInfo.winningLine);
      }
    }
  }, [stepNumber, history, isXNext, isSteppingBack]);
  // Handler function for when a square is clicked
  const handleClick = (index) => {
    // Create copies of the history and squares array and get the current state
    const historyCopy = history.slice(0, stepNumber + 1);
    const current = historyCopy[stepNumber];
    const squaresCopy = current.slice();
    
    // If the square is already filled or there is a winner, return early
    if (squaresCopy[index] || checkWinner(squaresCopy)) return;
    
    // If it's AI's turn and the game is stepping back, return early
    if (!isXNext && isSteppingBack) return;

    // Set the value of the clicked square and update the state
    squaresCopy[index] = isXNext ? 'X' : 'O';
    setHistory(historyCopy.concat([squaresCopy]));
    setStepNumber(historyCopy.length);
    setIsXNext(!isXNext);
    setIsSteppingBack(false);

    // Check if there is a winner and update the winning line state
    const winnerInfo = checkWinner(squaresCopy);
    if (winnerInfo) {
      setWinningLine(winnerInfo.winningLine);
    }
  };

  // Handler function to step back in the game's history
  const handleBack = () => {
    // Step back by 2 steps or to the beginning if there are not enough steps
    if (stepNumber > 1) {
      setStepNumber(stepNumber - 2);
    } else {
      setStepNumber(0);
    }
    setIsXNext(true);
    setIsSteppingBack(true);
  };

  // Handler function to restart the game
  const handleRestart = () => {
    // Reset the step number, turn, and winning line state to their initial values
    setStepNumber(0);
    setIsXNext(true);
    setWinningLine(null);
  };
  // Function to find the best move for the AI player using the minimax algorithm
  const findBestMove = (board, player, opponent) => {
    // Initialize the best score to negative infinity and the best move to null
    let bestScore = -Infinity;
    let bestMove = null;

    // Loop through all squares on the board
    for (let i = 0; i < board.length; i++) {
      // If the square is empty, make a move and calculate the score using the minimax algorithm
      if (!board[i]) {
        board[i] = player;
        let score = minimax(board, 0, false, player, opponent);
        board[i] = null;

        // If the score is better than the best score so far, update the best score and the best move
        if (score > bestScore) {
          bestScore = score;
          bestMove = i;
        }
      }
    }
    // Return the best move found
    return bestMove;
  };

  // Function to check if there is a winner and return the winner and the winning line
  function checkWinner(squares) {
    // Define the possible winning lines
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    
    // Check each line to see if there is a winner
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        // If there is a winner, return the winner and the winning line
        return { winner: squares[a], winningLine: lines[i] };
      }
    }
    // Return null if there is no winner
    return null;
  }

  // Getting the current state of the game board from the history
  const current = history[stepNumber];

  // Rendering the Board component with the current state and handlers for click events
  // Buttons to step back in the game's history and to restart the game
  return (
    <div>
      <Board
        squares={current}
        onClick={(i) => handleClick(i)}
        winningLine={winningLine}
      />
      <button className="timeButton" onClick={handleBack}>BACK</button>
      <button className="timeButton" onClick={handleRestart}>RESTART</button>
    </div>
  );
}

// Exporting the Game component as the default export
export default Game;
