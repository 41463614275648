import React from 'react';

// The Board component representing the game board of the tic-tac-toe game
function Board({ squares, onClick, winningLine }) {

  // Helper function to render a single square on the board
  function renderSquare(i) {
    return (
      <button
        key={i}
        style={{
          // Setting the width and height of the square
          width: '100px', 
          height: '100px',
          // Changing the background color to red if the square is part of the winning line
          backgroundColor: winningLine && winningLine.includes(i) ? 'red' : 'white', 
        }}
        // Calling the onClick prop function when the square is clicked
        onClick={() => onClick(i)}
        // Displaying the value (X or O) of the square
      >
        {squares[i]} 
      </button>
    );
  }

  return (
    <div style={{
      // Creating a 3x3 grid to hold the squares
      // Rendering all the squares on the board using a map function
      display: 'grid', 
      gridTemplateRows: 'repeat(3, 1fr)', 
      gridTemplateColumns: 'repeat(3, 1fr)', 
      gap: '5px'}}>
      {[...Array(9).keys()].map((i) => renderSquare(i))}
    </div>
  );
}

// Exporting the Board component as the default export
export default Board;
