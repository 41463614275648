// The function recursively evaluates every possible move on the board 
// for both the AI (maximizing player) and the human (minimizing player). 
// The function returns a score based on the outcome of the game: win, loss, or draw.
// AI Win: +1, Draw: 0, Human Win: -1
function minimax(board, depth, isMaximizing, player, opponent) {
  // Mapping of scores for each outcome (win, lose, draw) to facilitate score calculation
  const scores = { [player]: 1, [opponent]: -1, draw: 0 };

  // Check if there is a winner and return the corresponding score
  const winner = checkWinner(board, player, opponent);
  if (winner) return scores[winner];

  // Check if the board is full (draw) and return the corresponding score
  if (isFull(board)) return scores.draw;

  // Maximizing player's turn (AI) to find the best score by exploring potential moves
  if (isMaximizing) {
    let bestScore = -Infinity;
    for (let i = 0; i < board.length; i++) {
      if (!board[i]) {
        board[i] = player;
        let score = minimax(board, depth + 1, false, player, opponent);
        board[i] = null;
        bestScore = Math.max(score, bestScore);
      }
    }
    return bestScore;
  } 
  // Minimizing player's turn (human) to find the best score by exploring potential moves
  else {
    let bestScore = Infinity;
    for (let i = 0; i < board.length; i++) {
      if (!board[i]) {
        board[i] = opponent;
        let score = minimax(board, depth + 1, true, player, opponent);
        board[i] = null;
        bestScore = Math.min(score, bestScore);
      }
    }
    return bestScore;
  }
}

// Helper function to check if the board is full (no null values remain), indicating a draw
function isFull(board) {
  return board.every((cell) => cell);
}

// Helper function to check if there is a winner based on the current state of the board
function checkWinner(board, player, opponent) {
  // Defining the possible winning combinations in the Tic Tac Toe game
  const winningCombinations = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];

  // Checking each combination to see if there is a winner
  for (const combination of winningCombinations) {
    const [a, b, c] = combination;
    if (board[a] && board[a] === board[b] && board[a] === board[c]) {
      // Returning the winner (player or opponent) based on the winning combination
      return board[a] === player ? player : opponent;
    }
  }
  // Returning null if there is no winner
  return null;
}

// Exporting the minimax function as the default export
export default minimax;
