import React from 'react'; 
import Game from './Game'; 
import Header from "./Header"; 

// The main App component that represents the entire application
// Including the Header component to display the header section
// Including the Game component to display the main game interface
function App() {
  return (
    <div>
      <Header /> 
      
      <Game /> 
    </div>
  );
}

// Exporting the App component as the default export
export default App;
